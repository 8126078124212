:root {
  --bg-color-main: #F8F8F8;
  --bg-color-alt: white;
  --bg-color-alt2: #ededed;

  --text-color-main: #9E9E9E;
  --text-color-alt: black;
  --text-color-special: #4E9FEB;

  --shadow-color: #e4e4e4;

  --font-title: 'Montserrat', sans-serif;
  --font-standard: 'Source Code Pro', monospace;
}

.darkmode {
  --bg-color-main: #0a192f;
  --bg-color-alt: #1e3352b7;

  --text-color-main: #ccd6f6;
  --text-color-alt: #e6f1ff;
  --text-color-special: #4E9FEB;

  --shadow-color: #040911;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--bg-color-main);
  font-family: var(--font-standard);
  color: var(--text-color-main);
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: inherit;
}
