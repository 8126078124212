.projects-container {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.projects-main {
    max-width: 900px;
    margin: 100px 20px 0 20px;
}

.title-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.projects-main .title {
    font-family: var(--font-title);
    color: var(--text-color-special);
    font-size: 3em;
    font-weight: 800;
    padding: 0;
    margin: 0;
}

.projects-main .title-description {
    font-size: 0.9em;
    padding: 0;
    margin: 0;
    font-weight: 700;
}

/* FILTER SYSTEM */

.filter-container {
    background-color: var(--bg-color-alt);
    border-radius: 8px;
    width: 900px;
    margin: 20px auto auto auto;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    transition: ease 300ms;
}

@media only screen and (max-width: 930px) {
    .filter-container {
        width: 100%;
    }
  }


.filter-tags {
    margin: 0 10px 10px 10px;
}

.filter-tag {
    color: var(--text-color-main);
    background-color: var(--bg-color-alt);
    border: 0;
    
    display: inline-flex;
    white-space: pre;
    font-size: 0.7rem;
    padding: 3px;
    margin: 5px;
    /* background-color: var(--bg-color-main); */
    border-radius: 5px;
    cursor: pointer;
}

.filter-tag-selected {
    color: var(--text-color-special);
    background-color: var(--bg-color-main);
}


.filter-container input {
    font-family: var(--font-main);
    color: var(--text-color-special);
    background-color: transparent;
    margin: 10px;
    border-radius: 5px;
    border: 0;
    padding: 5px;
    justify-self: stretch;
    overflow: hidden;
    transition: ease 300ms;
}

input::placeholder {
    color: var(--text-color-main);
}


.filter-container:hover {
    box-shadow: 0 0 30px var(--shadow-color);
}



.projects-collection {
    max-width: 900px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-container {
    background-color: var(--bg-color-alt);
    margin: 30px 0 0 0;
    padding: 0;
    border-radius: 8px;
    font-size: 0.9em;
    transition: ease 300ms;
}

.project-container:hover {
    box-shadow: 0 0 30px var(--shadow-color);
}

.project-container:hover .folder {
    color: var(--text-color-special);
}

.project-container:hover .project-icons-links {
    color: var(--text-color-special);
}

.project-tile {
    padding: 15px;
    display: grid;
    grid-template-areas:
    "icon title icons"
    "desc desc desc"
    "tags tags date";
    align-items: center;
    grid-template-columns: 30px auto auto;
}

.folder {
    grid-area: icon;
    color: var(--text-color-main);
    justify-self: start;
    transition: ease 300ms;
}

.project-title {
    /* background-color: blue; */
    grid-area: title;
    white-space: nowrap;
    color: var(--text-color-alt);
    font-family: var(--font-title);
    margin: 0;
    font-size: 1em;
}

.project-icons-links {
    /* background-color: red; */
    grid-area: icons;
    display: flex;
    flex-direction: row;
    /* width: 50px; */
    color: var(--text-color-main);
    justify-content: space-between;
    column-gap: 13px;
    justify-self: end;
    transition: ease 300ms;
}

.project-description {
    grid-area: desc;
    margin: 10px 0 10px 0;
    padding: 0;
    font-size: 0.9em;
}

.tags {
    grid-area: tags;
    margin: 0;
    padding: 0;
    color: var(--text-color-special);
    font-size: 0.7rem;
    display: flex;
    flex-flow: row wrap;
}

.tags span {
    margin-right: 4.5%;
}

.project-date {
    grid-area: date;
    margin: 0;
    padding: 0;
    color: var(--text-color-special);
    font-size: 0.7rem;
    justify-self: end;
}





/* switch FROM header */



/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 28px;
}
  
  /* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
    border-radius: 8px;
}
  
  /* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
  
input:checked + .slider {
    background-color: var(--bg-color-alt);
}
  
input:focus + .slider {
    box-shadow: 0 0 1px var(--bg-color-alt);
}
  
input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}
  
  /* Rounded sliders */
.slider.round {
    border-radius: 20px;
}
  
.slider.round:before {
    border-radius: 50%;
}
