.projectsList-container {
    margin: 100px 0 0 0;
    padding: 0;
    /* background-color: red; */
    /* width: 100%; */
    max-width: 1000px;
    display: flex;
    flex: row;
    align-items: flex-start;
}

/* .current-projects {
    width: 100%;
    
} */

.section-title {
    /* width: 100%; */
    background-color: var(--bg-color-alt);
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 10px 
}

.projectAdmin-tile {
    background-color: var(--bg-color-alt);
    border-radius: 8px;
    margin-bottom: 20px;
}


.project-button {
    cursor: pointer;
    margin-top: 10px;
    background-color: var(--bg-color-main);
    border: 1px solid var(--text-color-main);
    border-radius: 4px;
    color: var(--text-color-main);
    padding: 3px;
    width: 25px;
    height: 25px;
    transition: ease 200ms;
}

.project-button:hover {
    background-color: var(--bg-color-alt);
}



/*////////////////////////////*/
/*/// CREATING NEW PROJECT ///*/
/*////////////////////////////*/

.new-project {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.new-project label {
    align-self: start;
    font-size: 0.8rem;
}

.new-project input {
    width: calc(100% - 10px);
    padding: 5px;
    border-radius: 4px;
    border: 1px solid var(--text-color-main);
    background-color: var(--bg-color-main);
    margin: 5px 0;
    color: var(--text-color-alt);
}

.new-project button {
    cursor: pointer;
    margin-top: 10px;
    background-color: var(--bg-color-main);
    border: 1px solid var(--text-color-main);
    border-radius: 4px;
    color: var(--text-color-main);
    padding: 3px;
    height: 25px;
    transition: ease 200ms;
    margin-right: 5px;
}

.new-project .btn-icon {
    width: 25px;
}

.new-project button:hover {
    background-color: var(--bg-color-alt);
}


/*/////////////////////*/
/*/// CONTROL PANEL ///*/
/*/////////////////////*/

.control-panel * {
    margin: 0;
    padding: 0;
}


.control-panel {
    background-color: var(--bg-color-alt);
    padding: 10px;
    margin: 20px 0 0 20px;
    border-radius: 8px;
    width: 315px;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.control-panel button {
    background-color: var(--bg-color-main);
    width: 100%;
    margin-top: 15px;
    transition: ease 200ms;
}

.control-panel button:hover {
    background-color: var(--bg-color-alt);
}

/*/////////////////////*/
/*/// MEDIA QUERIES ///*/
/*/////////////////////*/

@media only screen and (max-width: 800px) {
    .projectsList-container {
        flex-direction: column-reverse;
    }

    .control-panel {
        width: 100%;
        max-width: 1000px;
        margin: 0;
        padding: 0px;
    }

    .projects-num {
        margin-top: 5px;
    }

    .control-panel button {
        width: calc(100% - 20px);
        margin-bottom: 10px;
    }
  }