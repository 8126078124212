.login-form {
    background-color: var(--bg-color-alt);
    box-shadow: 0 0 20px var(--shadow-color);
    border-radius: 8px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.login-form .login-title {
    text-align: center;
    margin-bottom: 20px;
    font-family: var(--font-title);
    font-weight: 500;
    font-size: 1.5rem;
    min-width: 14rem;
}

.login-form label {
    font-family: var(--font-title);
    font-size: 0.9rem;
    /* margin-left: 10px; */
    /* transform: translateY(30px); */
    transition: ease 200ms;
    pointer-events: none;
}

.login-form input {
    border: none;
    /* border-radius: 4px; */
    /* border: 1px solid var(--text-color-main); */
    border-bottom: 1.5px solid var(--text-color-main);
    margin-bottom: 10px;
    padding: 7px;
    text-align: center;
    color: var(--text-color-main);
    background-color: transparent;
}

/* .login-form input:empty + label{
    transform: translateY(30px);
} */
/* 
input:hover  label {
    transform: translateY(0px);
    background-color: red;
} */


.login-form button {
    margin-top: 10px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid var(--text-color-main);
    background-color: var(--bg-color-main);
    color: var(--text-color-main);
    font-family: var(--font-title);
    font-weight: 700;
    cursor: pointer;
    transition: ease 200ms;
}

.login-form button:hover {
    background-color: transparent;
}

.error {
    max-width: 100%;
    font-family: var(--font-title);
    color: rgb(255, 0, 0);
    font-size: 0.8rem;
}