.navbar-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    transition: ease 300ms;
    z-index: 1000;
}

.navbar-container:hover {
    background-color: var(--bg-color-alt);
    box-shadow: 0 5px 20px var(--shadow-color);
    height: 70px;
}

.navbar-container:hover .logo {
	height: 40px;
}

.navbar-container:hover ul button {
    height: 40px;
}

.navbar-main {
    height: 100%;
    padding: 0;
    margin: 0 50px 0 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo {
    
    height: 25px;
    width: 40px;
    border: 1px solid;
    border-radius: 8px;
    border-color: var(--text-color-special);
    color: var(--text-color-special);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease 300ms;
}

.logo p {
    font-family: var(--font-title);
    font-weight: 500;
    font-size: 0.85em;
    transition: ease 300ms;
}

.logo:hover {
    background-color:var(--text-color-special);
    border-radius: 50%;
    box-shadow: 0 0 5px ;
}

.logo:hover p {
    font-weight: 700;
    color: var(--bg-color-alt);
}

nav ul{
    /* width: 100px; */
    text-decoration: none;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* align-items: center; */
}

nav ul li{
    /* font-size: 0.9rem; */
    font-weight: 300;
    margin-left: 15px;
    transition: ease 200ms;
}

nav ul li:hover {
    color: var(--text-color-special);
}

/* ul button {
    background-color: transparent;
    height: 25px;
    width: 40px;
    border: 1px solid;
    border-radius: 5px;
    border-color: var(--text-color-special);
    color: var(--text-color-special);
    font-weight: 500;
    transition: ease 300ms;
    cursor: pointer;
}

ul button:hover {
    background-color:var(--text-color-special);
    color: var(--bg-color-alt);
} */


.fa-arrow-circle-up {
    position:fixed;
    /* position: absolute; */
    right: 30px;
    bottom: 30px;
    cursor: pointer;
    transition: ease 250ms;
}

.fa-arrow-circle-up:hover {
    color: var(--text-color-special);
}


@media only screen and (max-width: 900px) {
    .navbar-container {
        background-color: var(--bg-color-alt);
    }

    .navbar-main {
        margin: 0 20px 0 20px;
    }
}